.wrapper {
  display: flex;
}

.handle {
  display: flex;
  justify-content: center;
  padding: 20px 10px;
  cursor: move;
}

.content {
  flex: 1;
}
