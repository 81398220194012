.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.toggle {
  margin-right: 6px;
}

.list {
  width: 33%;
  padding: 24px;
}
