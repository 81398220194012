.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #f0f2f5;
  padding: 12px;
  margin-bottom: 24px;
}
