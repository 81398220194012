html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#root,
.App {
  width: 100%;
  height: 100%;
}

header {
  font-size: 20px;
}

img {
  vertical-align: middle;
}

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
a:active,
a:hover,
a:focus {
  color: #000;
  text-decoration: none;
}

.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
