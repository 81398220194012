.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.form {
  flex: 1;
  min-height: 500px;
  width: 100%;
}

.preview {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 90vh;
}

.preview iframe {
  border: 0;
  flex: 1;
}
