.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.form {
  flex: 1;
}

.preview {
  display: flex;
  min-height: 90vh;
  flex-direction: column;
}

.preview iframe {
  border: 0;
  flex: 1;
}
