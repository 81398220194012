.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-image: url('./svg/pattern.svg');
  background-size: 100;
  background-color: #f5f5f5;
  flex-direction: column;
  box-sizing: border-box;
}

.error:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2;
  background-color: red;
}

.preview,
.preview img {
  max-width: 100%;
}

.form {
  text-align: center;
}

.orLabel {
  margin-top: 10;
  margin-bottom: 8;
}

.fileList {
  width: 100%;
}

.file {
  margin-bottom: 8;
}
